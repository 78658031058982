import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuResolverService {
  dataUrl = 'assets/data/menus.json';
  constructor(private http: HttpClient) { }
  getData() {
    return this.http.get(this.dataUrl);
  }
}
