import {TextBlockModel} from "./text-block.model";

export class ArticleSend {
  fileData: File | string;
  title: string;
  date: string;
  articleText: string | TextBlockModel[];
  slug: string;
  author: number;
  intro?: string;
  imgDescr?: string;
  subTitle?: string;
  photographer?: string;
  photographerLink?: string;
  city: string;

  constructor(fileData, title, date, articleText, slug, author, subTitle, imgDescr, intro, photographer, photographerLink, city) {
    this.fileData = fileData;
    this.title = title;
    this.date = date;
    this.articleText = articleText;
    this.slug = slug;
    this.author = author;
    this.intro = intro;
    this.imgDescr = imgDescr;
    this.subTitle = subTitle;
    this.photographer = photographer;
    this.photographerLink = photographerLink;
    this.city = city;
  }
}
