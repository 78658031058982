import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertBoxDispatcherService {

  constructor(private alertController: AlertController) { }

  async messageBox(title: string, message: string) {
    const alert =  await this.alertController.create({
      animated: true,
      header: title,
      message,
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
    await alert.present();
  }

  public async chooseBox(title: string, message: string, confirmButtonText: string, confirmCallback: (value: any) => any, cancelButtonText: string, cancelCallback?: (value: any) => any) {
    const buttons = [
      {
        text: confirmButtonText,
        handler: confirmCallback
      },
      {
        text: cancelButtonText,
        role: 'cancel',
        cssClass: 'secondary',
      }
    ];
    if (typeof cancelCallback !== 'undefined') {
      buttons[1].handler = cancelCallback;
    }

    const alert = await this.alertController.create({
      animated: true,
      header: title,
      message,
      buttons
    });
    await alert.present();
  }
}
