import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StaticallyCdnService} from "../../../core/services/statically-cdn.service";

@Component({
  selector: 'app-remote-picker-element',
  templateUrl: './remote-picker-element.component.html',
  styleUrls: ['./remote-picker-element.component.scss'],
})
export class RemotePickerElementComponent implements OnInit, OnChanges {
  @Input() img: string;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  imgData: ImgRemoteModel = new ImgRemoteModel();
  isSelected = false;
  constructor(private cdn: StaticallyCdnService) {}

  ngOnInit() {
  }

  getUrl(){
    return this.img;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.img.currentValue !== changes.img.previousValue){
      this.imgData.original = this.img;
      this.imgData.compressed = this.cdn.getCdnFromUrl(true, this.img, [200, 200], 50);
    }
  }

  select() {
    this.selected.emit(this.imgData.original);
  }

  markDeselect() {
    this.isSelected = false;
  }

  markSelected() {
    this.isSelected = true;
  }
}

class ImgRemoteModel{
  original = '';
  compressed = '';
}
