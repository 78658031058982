import {App} from '@capacitor/core';
import {Platform} from '@ionic/angular';

export class AbstractPages {
  constructor(public platform: Platform) {
      this.platform.backButton.subscribeWithPriority(-1, () => {
        navigator['app'].exitApp();
      });
  }
}
