import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  token = new BehaviorSubject<string>('');
  constructor(private storage: Storage , private platform: Platform) {
    this.setUp();
  }

  setToken(value: string){
    this.storage.ready().then(() =>this.storage.set('access_token', value));
    this.token.next(value);
  }

  getToken(){
    return this.token.getValue();
  }
  async getAsyncToken(){
    return await this.storage.ready().then(() => this.storage.get('access_token').then(value => { if(!!value){return value;}}));
  }

  delete(){
    this.storage.ready().then(() =>this.storage.remove('access_token'));
    this.token.next('');
  }
  public getTokenCallback(callback: (token: string) => any){
    //if (this.platform.is('android') || this.platform.is('ios')) {
      this.getAsyncToken().then(value => callback(value));
   /* } else {
      callback(this.getToken());
    }*/
  }

  private setUp() {
    this.storage.ready().then(() => this.storage.get('access_token').then(value => { if(!!value){this.token.next(value);}}));
  }



}
