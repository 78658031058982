import {Component, Input, OnInit} from '@angular/core';
import {MenuEntries} from '../../core/models/menu-entries';

@Component({
  selector: 'app-menu-entry',
  templateUrl: './menu-entry.component.html',
  styleUrls: ['./menu-entry.component.scss'],
})
export class MenuEntryComponent implements OnInit {
  @Input() menuEntry: MenuEntries;
  constructor() { }

  ngOnInit() {
    console.log(this.menuEntry);
  }

  toggleMenu() {
    this.menuEntry.toggle = !this.menuEntry.toggle;
  }
}
