import { Injectable } from '@angular/core';
import {ProjectName} from '../enums/project-name.enum';
import {map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {EnvironmentsService} from './environments.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ResponseMessageModel} from "../models/ResponseMessageModel";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient, private env: EnvironmentsService,private jwtHelper: JwtHelperService) { }

  loadCities(env: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/searchcities`,
      {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }
  /**
   * recupera dal backend una proposta di slug in base al titolo assegnato
   */
  public getSlugProposal(title: string, proposalType: string, env: string){
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/getslugproposal/${title}/${proposalType}`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  searchCities(env: string, query: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/searchcities/${query}`,
      {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  getAuthorId(env: string, slug: string){
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/getAuthorId${(slug !== '')? '/' + slug: ''}`,
      {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }


}
