import {Routes} from '@angular/router';
import {UserGuard} from '../../core/guards/user.guard';

export class ScopriloRoutingModule {
  static scopriloRoutes: Routes = [
    {
      path: 'scoprilo/:env/add-articles',
      loadChildren: () => import('../../pages/scoprilo/add-articles/add-articles.module').then(m => m.AddArticlesPageModule),
      canActivate: [UserGuard],
    },
    {
      path: 'scoprilo/:env/manage-articles',
      loadChildren: () => import('../../pages/scoprilo/manage-articles/manage-articles.module').then(m => m.ManageArticlesPageModule),
      canActivate: [UserGuard],
    },
    /*{
      path: 'gallery-modal',
      loadChildren: () => import('./pages/scoprilo/modals/gallery-modal/gallery-modal.module').then( m => m.GalleryModalPageModule)
    },
    {
      path: 'folder/:id',
      loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
    }*/
  ];
}
