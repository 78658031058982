import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GalleryService} from '../../../../core/services/gallery.service';
import {JwtService} from '../../../../core/services/jwt/jwt.service';
import {LoaderDispatcherService} from '../../../../core/services/loader-dispatcher.service';
import {ResponseMessageModel} from "../../../../core/models/ResponseMessageModel";



@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.page.html',
  styleUrls: ['./gallery-modal.page.scss'],
})
export class GalleryModalPage implements OnInit {
  existingImg: File | string;
  file: File | string;
  imgs: Array<string>;
  source: string;
  env: string;
  acceptedImg = 'image/jpeg, image/png, image/gif, image/bmp';

  constructor(private modalController: ModalController, private gallery: GalleryService, private jwt: JwtService, public loading: LoaderDispatcherService,) { }

  ngOnInit() {
    if(!!this.existingImg){
      this.file = this.existingImg;
    }
    this.loading.show();
    this.jwt.getTokenCallback(token => {
      if(this.source === 'header') {
        this.gallery.getExistingImgs(this.env, token).subscribe(value => {
         this.loadImage(value);
        });
      } else if (this.source === 'body') {
        this.gallery.getExistingBodyImgs(this.env, token).subscribe(value => {
          this.loadImage(value);
        });
      }
    });
  }

  loadImage(datas: ResponseMessageModel){
    this.loading.dismiss();
    this.imgs = datas.data as Array<string>;
  }

  handleFileInput(event: any) {
    this.file = event.target.files[0];
  }

  confirm() {
    this.modalController.dismiss(this.file, 'confirm');
  }

  undo() {
    this.modalController.dismiss({}, 'undo');
  }

  selectedImg(url: string) {
    this.file = url;
  }

  checkFileMessage() {
    return !!this.file && this.file instanceof File;
  }
}


