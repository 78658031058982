import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { ArticleFormComponent } from './article-form.component';
import {HeaderModule} from '../../header/header.module';
import {ImageRemotePickerModule} from '../../image-remote-picker/image-remote-picker.module';
import {QuillModule} from 'ngx-quill';
import {EditorModule} from '@tinymce/tinymce-angular';
import {IonicSelectableModule} from "ionic-selectable";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderModule,
    ReactiveFormsModule,
    ImageRemotePickerModule,
    QuillModule,
    EditorModule,
    IonicSelectableModule,
  ],
  exports: [
    ArticleFormComponent
  ],
  declarations: [ArticleFormComponent]
})
export class ArticleFormModule {}
