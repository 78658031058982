import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {HttpClient} from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
@Injectable({
  providedIn: 'root'
})
export class SaveFilesService {
  constructor(private http: HttpClient, private nativeHTTP: HTTP, private file: File, private fileOpener: FileOpener) { }

  saveFile(url: string, filename: string){
    this.downloadFile(this.file.cacheDirectory + filename, url).then((entry) => {
      console.log('download complete: ' + entry.toURL());
    }, (error) => {
      // handle error
    });
  }

  saveFileAndExecute(url: string, filename: string, mimeType: string){
    this.downloadFile(this.file.cacheDirectory + filename, url).then((entry) => {
      console.log('download complete: ' + entry.toURL());
      this.fileOpener.open(this.file.cacheDirectory + filename, mimeType).then(() => console.log('File is opened'));
    }, (error) => {
      console.log('error file: ' + error);
    });
  }

  downloadFile(path: string, url: string){
    return this.nativeHTTP.downloadFile(url, {}, {}, path);
  }
}
