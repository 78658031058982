import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticallyCdnService {

  constructor() {
  }

  public getCdnFromUrl(webpAllowed: boolean, url?: string, dimension?: number[], quality?: number): string {
    let domain = '';
    let file = '';
    let isFirstParamInserted = false;
    if (url !== undefined && url !== null) {

      url = url.replace('https://', '');
      const splittedUrl = url.split('/');
      domain = splittedUrl[0];
      splittedUrl.shift();
      file = splittedUrl.join('/');
    } else {
      return null;
    }
    let result = `https://cdn.statically.io/img/${domain}/`;
    if (webpAllowed) {
      isFirstParamInserted = true;
      result += 'f=auto';
    }

    if (!!dimension) {
      if (isFirstParamInserted) {
        result += ',';
      }
      if (!!dimension[0]){
        result += `w=${dimension[0]}`;
      }
      if (!!dimension[1]) {
        if (!!dimension[0]) {
          result += ',';
        }
        result += `h=${dimension[1]}`;
      }
    }

    if (!!quality) {
      if (isFirstParamInserted) {
        result += ',';
      }
      result += `quality=${quality}`;
    }
    else{
      if (isFirstParamInserted) {
        result += ',';
      }
      result += `quality=100`;
    }

    result += `/${file}`;

    return result;
  }
}
