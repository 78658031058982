import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImageRemotePickerComponent} from './image-remote-picker/image-remote-picker.component';
import {RemotePickerElementComponent} from './remote-picker-element/remote-picker-element.component';
import {IonicModule} from '@ionic/angular';



@NgModule({
  declarations: [ImageRemotePickerComponent, RemotePickerElementComponent],
  exports: [
    ImageRemotePickerComponent, RemotePickerElementComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class ImageRemotePickerModule { }
