import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectName} from '../enums/project-name.enum';
import {EnvironmentsService} from './environments.service';
import {map} from 'rxjs/operators';
import {ResponseMessageModel} from "../models/ResponseMessageModel";


@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient, private env: EnvironmentsService) { }

  getExistingImgs(env: string, token: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/gallery/getexistingimgs`, {headers: {Authorization: `Bearer ${token}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  getExistingBodyImgs(env: string, token: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/gallery/getexistingbodyimgs`, {headers: {Authorization: `Bearer ${token}`}}).pipe(map((data) => data as ResponseMessageModel));
  }
}
