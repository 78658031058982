import {Injectable} from '@angular/core';
import {EnvType} from '../enums/env-type.enum';
import {ProjectName} from '../enums/project-name.enum';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  public scopriloUrls: UrlsModel;
  public scopriloApiKey: string;
  public feroastUrls: UrlsModel;
  public feroastApiKey: string;
  public loginEndpoint: string;
  constructor() { }

  getKonpadBeEnvUrl(env: string, project: ProjectName): string {
    const proj = this.getProject(project);
    if(env === EnvType.devel){
      return proj.devel.konpadBE;
    } else if (env === EnvType.prod){
      return proj.prod.konpadBE;
    }
  }

  getCoreBeEnvUrl(env: string, project: ProjectName): string {
    const proj = this.getProject(project);
    if(env === EnvType.devel){
      return proj.devel.coreBE;
    } else if (env === EnvType.prod){
      return proj.prod.coreBE;
    }
  }
  getWebBeEnvUrl(env: string, project: ProjectName): string {
    const proj = this.getProject(project);
    if(env === EnvType.devel){
      return proj.devel.webBE;
    } else if (env === EnvType.prod){
      return proj.prod.webBE;
    }
  }
  getApiKey(project: ProjectName): string {
    let proj: string;
    if(project === ProjectName.scoprilo){
      proj = this.scopriloApiKey;
    } else {
      proj = this.feroastApiKey;
    }
    return proj;
  }
  private getProject(project: ProjectName){
    let proj: UrlsModel;
    if(project === ProjectName.scoprilo){
      proj = this.scopriloUrls;
    } else {
      proj = this.feroastUrls;
    }
    return proj;
  }
}

class EnvDescriptor {
  coreBE: string;
  konpadBE: string;
  webBE: string;
}

export class UrlsModel{
  devel: EnvDescriptor;
  prod: EnvDescriptor;
}
