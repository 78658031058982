import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
    // tslint:disable-next-line:variable-name
  private _apiBaseUrl: string;
  // tslint:disable-next-line:variable-name
  private _apiKey: string;
  // tslint:disable-next-line:variable-name
  private _dimImgOfferPreview = [410, 230];
  // tslint:disable-next-line:variable-name
  private _dimImgOffer = [533, 300];
  // tslint:disable-next-line:variable-name
  private _dimImgAgency = [533, 300];
  // tslint:disable-next-line:variable-name
  private _dimImgSmallPreview = [204, 207];
  // tslint:disable-next-line:variable-name
  private _dimImgGallerySmallPreview = [204, 207];
  // tslint:disable-next-line:variable-name
  private _storeUserKey = 'currentUser';
  // tslint:disable-next-line:variable-name
  private _loginRoute = '/login';
  // tslint:disable-next-line:variable-name
  private _afterLogoutRoute = '';


  constructor() { }

  /**
   * recupera l'url base per chiamare l'api (E' necessario impostarlo prima nell' app.module)
   */
  get apiBaseUrl(): string {
    return this._apiBaseUrl;
  }

  /**
   * imposta l'url base per chiamare l'api (E' consigliabile impostarlo in app.module)
   * @param value
   */
  set apiBaseUrl(value: string) {
    this._apiBaseUrl = value;
  }

  /**
   * recupera l'apiKey necessaria per chiamare le api (E' necessario impostarlo prima nell' app.module)
   */
  get apiKey(): string {
    return this._apiKey;
  }

  /**
   * recupera l'apiKey necessaria per chiamare le api (E' consigliabile impostarlo nell' app.module)
   * @param value
   */
  set apiKey(value: string) {
    this._apiKey = value;
  }

  /**
   * recupera la dimensione delle preview immagini per offerlist
   */
  get dimImgOfferPreview(): number[] {
    return this._dimImgOfferPreview;
  }

  /**
   * imposta la dimensione delle preview immagini per offerlist
   * @param value
   */
  set dimImgOfferPreview(value: number[]) {
    this._dimImgOfferPreview = value;
  }

  /**
   * recupera la dimensione delle immagini per le pagine dettaglio offerta
   */
  get dimImgOffer(): number[] {
    return this._dimImgOffer;
  }

  /**
   * imposta la dimensione delle immagini per le pagine dettaglio offerta
   * @param value
   */
  set dimImgOffer(value: number[]) {
    this._dimImgOffer = value;
  }
  /**
   * recupera la dimensione delle immagini per le pagine dettaglio agenzia
   */
  get dimImgAgency(): number[] {
    return this._dimImgAgency;
  }
  /**
   * imposta la dimensione delle immagini per le pagine dettaglio agenzia
   * @param value
   */
  set dimImgAgency(value: number[]) {
    this._dimImgAgency = value;
  }
  /**
   * recupera la dimensione delle preview immagini per agency list
   * @param value
   */
  get dimImgSmallPreview(): number[] {
    return this._dimImgSmallPreview;
  }
  /**
   * imposta la dimensione delle preview immagini per agency list
   * @param value
   */
  set dimImgSmallPreview(value: number[]) {
    this._dimImgSmallPreview = value;
  }
  /**
   * recupera la dimensione delle preview immagini per galleria
   * @param value
   */
  get dimImgGallerySmallPreview(): number[] {
    return this._dimImgGallerySmallPreview;
  }
  /**
   * imposta la dimensione delle preview immagini per galleria
   * @param value
   */
  set dimImgGallerySmallPreview(value: number[]) {
    this._dimImgGallerySmallPreview = value;
  }

  /**
   * recupera la key usata nell storemanager per memorizzare l'utente
   */
  get storeUserKey(): string {
    return this._storeUserKey;
  }

  /**
   * recupera la key usata nell storemanager per memorizzare l'utente
   * @param value
   */
  set storeUserKey(value: string) {
    this._storeUserKey = value;
  }

  /**
   * recupera la login route per userGuard
   */
  get loginRoute(): string {
    return this._loginRoute;
  }

  /**
   * imposta la login route per userGuard
   * @param value
   */
  set loginRoute(value: string) {
    this._loginRoute = value;
  }

  /**
   * recupera la logout route per userAuthService
   */
  get afterLogoutRoute(): string {
    return this._afterLogoutRoute;
  }

  /**
   * imposta la logout route per userAuthService
   * @param value
   */
  set afterLogoutRoute(value: string) {
    this._afterLogoutRoute = value;
  }
}
