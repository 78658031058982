import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MenuEntries} from './core/models/menu-entries';
import {MenuResolverService} from './core/services/menu-resolver.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Storage} from '@ionic/storage';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {AuthService} from './core/services/auth.service';
import {JwtService} from './core/services/jwt/jwt.service';
import {AlertBoxDispatcherService} from './core/services/alert-box-dispatcher.service';
import {GetNameModel} from './core/models/get-name.model';
import {StoreUserDataService} from './core/services/store-user-data.service';
import {MenuStructure} from './core/menu-structure';
import {Capacitor, Device} from "@capacitor/core";
import {AppVersion} from "@ionic-native/app-version/ngx";
import {VersionCheckService} from "./core/services/version-check.service";
import {VersionModel} from "./core/models/version-model";
import {SaveFilesService} from "./core/models/save-files.service";
import {Store} from "@ngrx/store";
import {selectCurrentMenuList} from "./core/store/menu/selectors/menu.selectors";
import {cloneDeep} from "lodash";
import {EnvModalComponent} from "./components/env-modal/env-modal.component";
//import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  appPages: MenuEntries[];
  visible = false;
  userName = '';
  proj: string;
  env: any;
  envIsSetup = false;

  constructor(private menu: MenuResolverService, private router: Router, public alertController: AlertController,
              private auth: AuthService, private storage: Storage, private jwt: JwtService,
              private alert: AlertBoxDispatcherService, public storeUserData: StoreUserDataService,
              private appVersion: AppVersion, private platform: Platform,
              private versionCheckService: VersionCheckService, private saveFile: SaveFilesService, private store: Store,
              public modalCtrl: ModalController) {
  }



  ngOnInit(): void {
    this.checkVersion();
    this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
    ).subscribe((evnt) => {
        if (!this.router.url.includes('/login') && this.router.url !== '/') {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    );
    this.checkToken();
    //this.menu.getData().subscribe(menu => this.appPages = menu as MenuEntries[]);


    //this.appPages = MenuStructure.structure;



  }
  ngAfterViewInit(): void {
    this.store.select(selectCurrentMenuList).subscribe(value => {
     this.setupEnvAndProject(value);
     if(this.envIsSetup){
       this.router.navigateByUrl('dashboard');
     } else {
       this.envIsSetup = true;
     }
    });
    /*this.store.subscribe(state => {
      console.log(state);
    });*/
  }
  settings() {
    this.router.navigateByUrl('/settings');
  }

  async logout() {
    await this.alert.chooseBox(
      'Logout',
      'Vuoi fare il logout?',
      'Si',
      () => {
        this.jwt.getTokenCallback(token => {
          this.auth.logout(token).subscribe(value => {
            this.storage.remove('access_token');
            this.router.navigateByUrl('/');
          }, error => {
            if (error.message === 'Not Logged') {
              this.storage.remove('access_token');
              this.router.navigateByUrl('/');
            } else {
              this.alert.messageBox('Logout', 'Logout non effettuato a causa di un errore');
            }
          });
        });
      },
      'No');
    /*const alert = await this.alertController.create({
      animated: true,
      header: 'Logout',
      message: 'Vuoi fare il logout?',
      buttons: [
        {
          text: 'Si',
          handler: () => {
            this.auth.logout().subscribe(value => {
              this.storage.remove('access_token');
              this.router.navigateByUrl('/');
            }, error => {
              this.storage.remove('access_token');
              this.router.navigateByUrl('/');});
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }
      ]
    });
    await alert.present();*/
  }

  async changeEnvProj() {
    const envProjModal = await this.modalCtrl.create({
      animated: true,
      keyboardClose: true,
      component: EnvModalComponent
    });
    return envProjModal.present();
  }

  private async checkToken() {
    this.jwt.getTokenCallback((token): void =>{
      if (token !== '') {
        this.auth.check(token).subscribe(value => {
          this.auth.getName(token).subscribe(value2 => {
            const v = value2 as GetNameModel;
            this.storeUserData.name = v.name;
          });
          this.auth.checkFirstPasswordChange(token).subscribe(value1 => {
            this.checkFirstPasswordSet(value1.firstSet);
          });
        }, async (error) => {
          await this.storage.remove('access_token');
        });
      }
    });
  }

  private checkFirstPasswordSet(firstSet: boolean) {
    if (!firstSet) {
      this.alert.chooseBox(
        'Password',
        'E\' necessario impostare la password per la prima volta, vuoi farlo adesso?',
        'Si',
        () => this.router.navigateByUrl('/settings'),
        'No',
        () => this.router.navigateByUrl('dashboard'));
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  private checkVersion() {

    if (Capacitor.isNative) {
      this.appVersion.getVersionNumber().then(value => {
        if (this.platform.is('android')) {
          this.versionCheckService.checkVersion(value, 'android').subscribe(async versioncheck => {
            const version = versioncheck as VersionModel;

            if(version.newVersion) {
              await this.alert.chooseBox(
                'Nuova Versione',
                'La versione '+ version.version +' è disponibile, vuoi scaricarla?',
                'Si',
                () => {
                  this.saveFile.saveFileAndExecute(version.url, 'konpad.apk', 'application/vnd.android.package-archive');
                },
                'No');
            }
          });
        } else if (this.platform.is('ios')){

        }
      });

    }
  }

  private setupEnvAndProject(value: (MenuEntries[] | string)[]) {
    if(!!value) {
      const menu = cloneDeep(value);
      this.appPages = menu[0] as MenuEntries[];
      this.proj = menu[1] as string;
      this.env = menu[2] as string;
    }
  }
}
