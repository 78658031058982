import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GalleryService} from '../../../../core/services/gallery.service';
import {JwtService} from '../../../../core/services/jwt/jwt.service';
import {ActivatedRoute} from '@angular/router';
import { combineLatest } from 'rxjs';
import {LoaderDispatcherService} from '../../../../core/services/loader-dispatcher.service';
import {FormGroup} from '@angular/forms';
import {ArticleSend} from '../../../../core/models/internal/article-send';

import {AlertBoxDispatcherService} from '../../../../core/services/alert-box-dispatcher.service';
import {ArticleService} from '../../../../core/services/article.service';
import {ArticleFormComponent} from '../../article-form/article-form.component';
import {NewsModel} from '../../../../core/models/news.model';
import {EnvType} from '../../../../core/enums/env-type.enum';
import {CommonService} from '../../../../core/services/common.service';
import {TextUtils} from '../../../../utils/text-utils';
import {TextBlockEnum} from '../../../../core/enums/text-block.enum';
import {TextBlockModel} from '../../../../core/models/internal/text-block.model';
import {ResponseMessageModel} from "../../../../core/models/ResponseMessageModel";

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './news-edit-modal.page.html',
  styleUrls: ['./news-edit-modal.page.scss'],
})
export class NewsEditModalPage implements OnInit, AfterViewInit {
  @ViewChild('form') formData: ArticleFormComponent;
  existingImg: File | string;
  file: File | string;
  imgs: Array<string>;
  env = EnvType.prod;
  data: NewsModel;
  addNewsForm: FormGroup;

  constructor(private modalController: ModalController, private gallery: GalleryService, private jwt: JwtService, public loading: LoaderDispatcherService,private alert: AlertBoxDispatcherService, private service: ArticleService, private common: CommonService) { }

  ngOnInit() {
  }

  async confirm(form: ArticleFormComponent) {
    const datas = form.getDatas();
    await this.alert.chooseBox(
      'Modifica Articolo',
      'Vuoi modificare l\'articolo?',
      'Si',
      () => {
        this.loading.show();
        if (form.isFormValid()) {
          if(!Array.isArray(datas.articleText)){
            if(typeof datas.articleText === 'string') {
              this.sendArticleDatas(datas, datas.articleText, form);
            } else {
              const dataText = datas.articleText as Array<TextBlockModel>;
              this.sendArticleDatas(datas, dataText[0].data, form);
            }
          } else {
            /*if (typeof datas.articleText[1] === 'string'){
              this.sendArticleDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], datas.articleText[1], datas.articleText[2]), form);
            } else{*/
            if(!!datas.articleText) {
              const imgLoad = datas.articleText.filter(value => value.type === TextBlockEnum.imgFile);
              if(imgLoad.length !== 0) {
                combineLatest(imgLoad.map(value => this.service.sendImgData([value.data], this.env, '/body/'))).subscribe(value => {
                  (datas.articleText as TextBlockModel[]).filter(v => v.type === TextBlockEnum.imgFile).forEach((v, i) => {
                    v.data = value[i].data[0];
                    v.type = TextBlockEnum.imgLink;
                  });
                  this.sendArticleDatas(datas, TextUtils.mergeBlocksText(datas.articleText as TextBlockModel[]), form);
                });
              } else {
                this.sendArticleDatas(datas, TextUtils.mergeBlocksText(datas.articleText as TextBlockModel[]), form);
              }
            }

            /*if(datas.articleText[1].length > 0) {
              this.service.sendImgData(datas.articleText[1], this.env, '/body/').subscribe(value => {
                this.sendArticleDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], value.data, datas.articleText[2]), form);
              });
            } else {
              this.sendArticleDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], [], datas.articleText[2]), form);
            }*/
           // }
          }
        }
      },
      'No');
  }

  private sendArticleDatas(datas: ArticleSend, textArticle: string, form){
    this.service.editArticleDatas(datas.fileData, datas.title,datas.date,
      textArticle, this.data.slug, datas.author, datas.intro, datas.imgDescr, datas.subTitle,
      datas.photographer, datas.photographerLink, datas.city, this.env)
      .subscribe(() => {
          this.loading.dismiss();
          this.alert.messageBox('Modifica Articolo', 'L\'articolo è stato modificato');
          form.resetForm();
          this.modalController.dismiss({}, 'confirm');
        },
        error => {
          this.loading.dismiss();
          const e = error.error as ResponseMessageModel;
          this.alert.messageBox('Creazione Articolo', 'Errore durante la modifica');
        });
  }

  undo() {
    this.modalController.dismiss({}, 'undo');
  }

  selectedImg(url: string) {
    this.file = url;
  }

  checkFileMessage() {
    return !!this.file && this.file instanceof File;
  }

  ngAfterViewInit(): void {
    this.common.getAuthorId(this.env, this.data.authorSlug).subscribe(r =>{
      const id = r.data.id as number;
      this.formData.setDatas(this.data.img, this.data.title, this.data.pubDate, this.data.description, id, this.data.subTitle, this.data.imgDescription, this.data.intro, this.data.photoReferenceName, this.data.photoReferenceLink, this.data.place);
    });
  }
}


