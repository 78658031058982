export class TextBlockModel {
  data: any;
  type: string;
  additional?: string;

  constructor(data: any, type: string, additional?: string){
      this.data= data;
      this.type = type;
      this.additional = additional;
  }
}

