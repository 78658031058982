import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreUserDataService {
  private _name = '';

  constructor() { }

  get name(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._name;
  }

  set name(value: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._name = value;
  }

}
