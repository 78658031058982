import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractPages} from '../../../core/abstracts/abstract-pages';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EnvType} from '../../../core/enums/env-type.enum';
import {PlaceModel} from '../../../core/models/place.model';
import {ScnPlaceTypeModel} from '../../../core/models/scn-place-type-model';
import {ModalController, Platform} from '@ionic/angular';
import {CommonService} from '../../../core/services/common.service';
import {LoaderDispatcherService} from '../../../core/services/loader-dispatcher.service';
import {ScopriloConNoiService} from '../../../core/services/scoprilo-con-noi.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {ArticleSend} from '../../../core/models/internal/article-send';
import {ScopriloConNoiSend} from '../../../core/models/internal/scoprilo-con-noi-send';

@Component({
  selector: 'app-scoprilo-con-noi-extra',
  templateUrl: './scoprilo-con-noi-extra-form.component.html',
  styleUrls: ['./scoprilo-con-noi-extra-form.component.scss'],
})
export class ScopriloConNoiExtraFormComponent extends AbstractPages implements OnInit, AfterViewInit {
  addNewsForm: FormGroup;
  env = EnvType.prod;
  cities: PlaceModel[];
  canShowEditor = false;
  ticketInfo: string;
  citySelected: number;
  placeType: ScnPlaceTypeModel[];
  tinyOptions = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic underline strikebackcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help' /*image*/,
  };



  constructor(private formBuilder: FormBuilder, private commonService: CommonService, public modalCtrl: ModalController,
              public loading: LoaderDispatcherService, public platform: Platform, private scnService: ScopriloConNoiService) {
    super(platform);
  }

  ngAfterViewInit(): void {
    // you can setTimeOut if display some message on your console
    this.canShowEditor = true;
  }

  ngOnInit() {
    this.addNewsForm = this.formBuilder.group({
      moreInfoUrl: ['', []],
      valuta: ['', []],
      price: ['', []],
      street: ['', []],
      ticketInfo: ['', []],
      placeType: ['', [Validators.required]],
    });
    /* this.commonService.loadCities(this.env).subscribe(data => {
       this.cities = data.data as PlaceModel[];
     });*/
    this.getPlaceType();
  }


  isFormValid(): boolean {
    return this.addNewsForm.valid;
  }

  isFormInvalid(): boolean {
    return this.addNewsForm.invalid;
  }


  resetForm() {
    this.addNewsForm.reset();
    this.cities = null;
    this.placeType = null;
    this.addNewsForm.value.moreInfoUrl = '';
    this.addNewsForm.value.street = '';
    this.addNewsForm.value.price = '';
    this.addNewsForm.value.valuta = null;
  }

  searchCity($event: { component: IonicSelectableComponent; text: string }) {
    if ($event.text.length > 3) {
      this.commonService.searchCities(this.env, $event.text).subscribe(data => {
        this.cities = data.data as PlaceModel[];
      });
    }
  }

  completeDatas(data: ArticleSend) {
    //console.log(this.addNewsForm.value.city.id);
    return new ScopriloConNoiSend(data.fileData, data.title, data.date, data.articleText, data.slug, data.author,
      data.city, this.addNewsForm.value.placeType.id, this.addNewsForm.value.price, this.addNewsForm.value.valuta,
      this.addNewsForm.value.moreInfoUrl, this.addNewsForm.value.street, this.addNewsForm.value.ticketInfo, data.subTitle, data.imgDescr, data.intro, data.photographer, data.photographerLink);
  }

  setDatas(moreInfoUrl: string, valuta: string, price: number, street: string, ticketInfo: string, placeType) {
    this.scnService.getPlaceType(this.env).subscribe(data => {
        this.placeType = data.data as ScnPlaceTypeModel[];
        this.addNewsForm.controls.placeType.setValue(this.placeType.find(v=>v.name === placeType));
    });

    /*this.commonService.searchCities(this.env, city.split(' (')[0]).subscribe(data => {
      this.cities = data.data as PlaceModel[];
      this.addNewsForm.controls.city.setValue(this.cities.find(v=>v.city === city.split(' (')[0] &&
        v.districtAbbreviation === city.substr(city.indexOf('(')+1, city.indexOf(')') - city.indexOf('(')-1) &&
        v.region === city.split(', ')[1]
        ));
    });*/

    this.addNewsForm.controls.moreInfoUrl.setValue(moreInfoUrl);
    this.addNewsForm.controls.valuta.setValue(valuta);
    this.addNewsForm.controls.price.setValue(price);
    this.addNewsForm.controls.street.setValue(street);

    this.addNewsForm.controls.ticketInfo.setValue(ticketInfo);
  }

  private getPlaceType() {
    this.scnService.getPlaceType(this.env).subscribe(data => {
      this.placeType = data.data as ScnPlaceTypeModel[];
    });
  }
}
