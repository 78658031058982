import {TextBlockModel} from '../core/models/internal/text-block.model';
import {TextBlockEnum} from '../core/enums/text-block.enum';

export class TextUtils {
  static imgStart = '<div class="imgbox"><img src="';
  static imgEnd = '"/>';
  static imgBlockEnd = '</div>';
  static imgDescrStart = '<p class="photo-description">';
  static imgDescrEnd = '</p></div>';
  static quoteStart = '<blockquote><p>';
  static quoteEnd = '</p></blockquote>';

  /**
   * @deprecated
   * @param upperText
   * @param imgUrl
   * @param lowerText
   */

  public static mergeImgInText(upperText: string, imgUrl: string[], lowerText?: string[]){
    let text = upperText;
    if(!lowerText){
      lowerText = Array<string>();
    }
    for (let i = 0; i < Math.max(imgUrl.length, lowerText.length); i++){
      if(!!imgUrl[i]) {
        text += '<img src="' + imgUrl[i] + '"/>';
      }
      if(!!lowerText[i]){
        text += lowerText[i];
      }
    }
    return text;
  }

  public static mergeBlocksText(text: TextBlockModel[]){
    return text.map(value => {
      if(value.type === TextBlockEnum.imgLink || value.type === TextBlockEnum.imgFile){
        let imgData = this.imgStart + value.data + this.imgEnd;
        if(!!value.additional){
          imgData = imgData.concat(this.imgDescrStart + value.additional + this.imgDescrEnd);
        } else{
          imgData = imgData.concat(this.imgBlockEnd);
        }
        return imgData;
      }
      else if(value.type === TextBlockEnum.quote){
        return this.quoteStart + value.data + this.quoteEnd;
      } else if(value.type === TextBlockEnum.text){
        return value.data;
      }
    }).join('');
  }

  public static extractBlocksText(text: string){
    const result: Array<TextBlockModel> = [];
    let indexString = 0;
    while(indexString < text.length - 1){
      const nextImgIndex = text.indexOf(this.imgStart, indexString);
      const nextQuoteIndex = text.indexOf(this.quoteStart, indexString);
      if((nextImgIndex < nextQuoteIndex && nextImgIndex !== -1) || (nextImgIndex !== -1 && nextQuoteIndex === -1)){
        result.push(new TextBlockModel(text.substring(indexString, nextImgIndex), TextBlockEnum.text));
        const img = this.extractImg(text.substring(nextImgIndex, text.length ));
        result.push(new TextBlockModel(img[0], TextBlockEnum.imgLink, img[1]));
        indexString = text.indexOf(this.imgBlockEnd, indexString) + this.imgBlockEnd.length;
      } else if((nextQuoteIndex < nextImgIndex && nextQuoteIndex !== -1) || (nextQuoteIndex !== -1 && nextImgIndex === -1)){
        result.push(new TextBlockModel(text.substring(indexString, nextQuoteIndex), TextBlockEnum.text));
        result.push(new TextBlockModel(this.extractQuote(text.substring(nextQuoteIndex, text.length)), TextBlockEnum.quote));
        indexString = text.indexOf(this.quoteEnd, indexString)+ this.quoteEnd.length;
      } else {
        result.push(new TextBlockModel(text.substring(indexString , text.length), TextBlockEnum.text));
        indexString = text.length - 1;
      }
    }
    return result;
  }

  private static extractImg(value: string){
    if (value.indexOf(this.imgDescrStart) !== -1 && value.indexOf(this.imgDescrStart) < value.indexOf(this.imgBlockEnd)){
      return[
        value.substr(value.indexOf(this.imgStart) + this.imgStart.length, value.indexOf(this.imgEnd) - (value.indexOf(this.imgStart) + this.imgStart.length)),
        value.substr(value.indexOf(this.imgDescrStart) + this.imgDescrStart.length, value.indexOf(this.imgDescrEnd) - (value.indexOf(this.imgDescrStart) + this.imgDescrStart.length))
      ];
    } else {
      return [value.substr(value.indexOf(this.imgStart) + this.imgStart.length, value.indexOf(this.imgEnd) - (value.indexOf(this.imgStart) + this.imgStart.length)), null];
    }
  }

  private static extractQuote(value: string){
    return value.substr(value.indexOf(this.quoteStart) + this.quoteStart.length, value.indexOf(this.quoteEnd)- this.quoteEnd.length +2);
  }
}
