import {Component, Input, OnInit} from '@angular/core';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  visible = false;
  constructor(public platform: Platform) { }

  ngOnInit() {
    /*this.router.events.pipe(
      map(value => {console.log(value); return value;}),
      filter(events => events instanceof NavigationEnd),
    ).subscribe(() => {
      if (this.router.url !== '/login' && this.router.url !== ''){
        this.visible = true;
      } else {
        this.visible = false;
      }
    });*/
  }

  downloadAndroidApp() {
    window.location.href = 'https://auth.scoprilo.net/apps/android/konpad.apk';
  }

  isAndroidVisible() {
    return this.platform.is('mobileweb');
  }
}
