import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EnvModalComponent} from './env-modal.component';
import {IonicModule} from "@ionic/angular";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [EnvModalComponent],
  exports:[EnvModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
    ]
})
export class HeaderEnvModule { }
