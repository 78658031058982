import { Action, createReducer, on } from '@ngrx/store';
import {MenuEntries} from '../../../models/menu-entries';
import {setMenuList} from "../actions/menu.actions";
import {MenuStructure} from "../../../menu-structure";


export const gameAnswersFeatureKey = 'headerTitle';

export interface State {
  menuList: MenuEntries[];
  project: string;
  env: string;
}

export const initialState: State = {
  menuList:  [MenuStructure.structure.find(value => value.title === 'Dashboard'),
    ...MenuStructure.structure
    .find((value) => value.type === 'project').subMenu
    .find((value) => value.type === 'env').subMenu],
  project: MenuStructure.structure.find((value) => value.type === 'project').title,
  env: MenuStructure.structure.find((value) => value.type === 'project').subMenu
    .find((value) => value.type === 'env').title,
};


export const menuReducer = createReducer(
  initialState,
  on(setMenuList, (data, input ) => ({
    menuList: [MenuStructure.structure.find(value => value.title === 'Dashboard'), ...input.menuList],
    env: input.env,
    project: input.project
  }))
);

