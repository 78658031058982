import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header.component';
import {IonicModule} from '@ionic/angular';
import {HeaderEnvModule} from "../env-modal/header-env.module";



@NgModule({
  declarations: [HeaderComponent],
  exports: [
    HeaderComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        HeaderEnvModule
    ]
})
export class HeaderModule { }
