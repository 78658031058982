import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentsService} from './environments.service';
import {ProjectName} from '../enums/project-name.enum';
import {ResponseMessageModel} from '../models/ResponseMessageModel';
import {map, switchMap} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UrlUtils} from '../../utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient, private env: EnvironmentsService, private jwtHelper: JwtHelperService) { }

  public sendImgData(file: any[], env: string , path?: string){
    if(!path){
      path = '/';
    }
    const formData = new FormData();
    file.forEach((value, index) => {
        formData.append('image' + index, value, value.name);
    });
    formData.append('path', path);
    formData.append('length', file.length.toString(10));
    return this.http.post(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/loadimg`, formData, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  public createArticle(title: string, date: string, imgUrl: string, article: string, slug: string, idAuthor: number, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, city: string, env: string){
    imgUrl = UrlUtils.changeCoreUrlWithWeb(imgUrl, this.env.getCoreBeEnvUrl(env, ProjectName.scoprilo), this.env.getWebBeEnvUrl(env, ProjectName.scoprilo));
    return this.http.post(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/createarticle`,
      {title, date, imgUrl, article, slug, slugAuthor: idAuthor, intro ,imgDescr, subtitle, photographer, photographerLink, city}, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  /**
   * @deprecated
   * recupera dal backend una proposta di slug in base al titolo assegnato
   */
  public getSlugProposal(title: string, env: string){
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/getslugproposal/${title}`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  public sendArticleDatas(imgFile: File | string, title: string, date: string, article: string, slug: string, idAuthor: number, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, city: string, env: string){
    if (imgFile instanceof File){
      return this.sendImgData([imgFile], env, '/').pipe(
        switchMap(value => this.createArticle(title, date, value.data[0], article, slug, idAuthor, intro ,imgDescr, subtitle, photographer, photographerLink, city, env))
      );
    } else {
      return this.createArticle(title, date, imgFile, article, slug, idAuthor, intro, imgDescr, subtitle, photographer, photographerLink, city, env);
    }


  }

  loadAuthors(env: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/getauthors`,
      {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  getAllReferrals(env: string){
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/getallreferrals`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  filterByReferral(env: string, referral: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/filternewsbyreferral?referral=${referral}`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  editArticleDatas(imgFile: File | string, title: string, date: string, article: string, slug: string, idAuthor: number, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, city: string, env: string){
    if (imgFile instanceof File){
      return this.sendImgData([imgFile], env, '/').pipe(
        switchMap(value => this.editArticle(title, date, value.data[0], article, slug, idAuthor, intro ,imgDescr, subtitle, photographer, photographerLink, city, env))
      );
    } else {
      return this.editArticle(title, date, imgFile, article, slug, idAuthor, intro, imgDescr, subtitle, photographer, photographerLink, city, env);
    }


  }

  private editArticle(title: string, date: string, imgUrl: string, article: string, slug: string, idAuthor: number,  intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, city: string, env: string) {
    imgUrl = UrlUtils.changeCoreUrlWithWeb(imgUrl, this.env.getCoreBeEnvUrl(env, ProjectName.scoprilo), this.env.getWebBeEnvUrl(env, ProjectName.scoprilo));
    return this.http.put(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/editarticle`,
      {title, date, imgUrl, article, slug, slugAuthor: idAuthor, intro ,imgDescr, subtitle, photographer, photographerLink, city}, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  delete(slug: string, env: string) {
    return this.http.delete(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/articles/deletearticle/${slug}`,
       {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }
}
