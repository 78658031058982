import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EnvironmentsService} from '../services/environments.service';
import {ProjectName} from '../enums/project-name.enum';
import {GlobalsService} from "../services/globals.service";

@Injectable()
export class RouteSecurityInterceptor implements HttpInterceptor {

  constructor(private globals: GlobalsService, private env: EnvironmentsService) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!request.url.includes('assets')) {
      request = request.clone({
        setHeaders: {
          // timestamp: Math.floor(Date.now() / 1000).toString(),
          apikey: this.getByUrl(request.url),
        },
        setParams: {
          // XDEBUG_SESSION_START: 'PHPSTORM', // TODO: debug eliminare
        },
      });
    }
    return next.handle(request);
  }


  private getByUrl(url: string) {
    if(url.includes('scoprilo')){
      return this.env.getApiKey(ProjectName.scoprilo);
    } else if(url.includes('feroast') && !url.includes(this.env.loginEndpoint)){
      return this.env.getApiKey(ProjectName.feroast);
    } else {
      return '';
    }
  }
}
