import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController, Platform} from '@ionic/angular';
import {GalleryModalPage} from '../modals/gallery-modal/gallery-modal.page';
import {ArticleService} from '../../../core/services/article.service';
import {LoaderDispatcherService} from '../../../core/services/loader-dispatcher.service';
//non rimuovere questo import serve a gallery-modal
import {AbstractPages} from '../../../core/abstracts/abstract-pages';
import {AuthorsModel} from '../../../core/models/authors-model';
import {ArticleSend} from '../../../core/models/internal/article-send';
import {EnvType} from '../../../core/enums/env-type.enum';
import {ArticleType} from '../../../core/enums/article-type.enum';
import {CommonService} from '../../../core/services/common.service';
import {TextBlockEnum} from '../../../core/enums/text-block.enum';
import {TextBlockModel} from '../../../core/models/internal/text-block.model';
import {TextUtils} from '../../../utils/text-utils';
import {ArticleComponentsOrderModel} from '../../../core/models/internal/article-components-order.model';
import { DatePipe } from '@angular/common';
import {PlaceModel} from "../../../core/models/place.model";
import {IonicSelectableComponent} from "ionic-selectable";

@Component({
  selector: 'app-articles-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss'],
})
export class ArticleFormComponent extends AbstractPages implements OnInit, AfterViewInit {
  @Input() editMode = false;
  cities: PlaceModel[];
  textBlockEnum = TextBlockEnum;
  addNewsForm: FormGroup;
  maxDate: string;
  todayDate: string;
  selectedDate: string;
  fileData: File | string;
  additionalImgBodyUrls: any[] = Array<any>();
  imgsCounter = 0;
  quotesCounter = 0;
  fileUrl: string;
  env = EnvType.prod;
  authors: AuthorsModel[];
  canShowEditor = false;
  selectedAuthor: number;
  articleBody: string[];
  slugProposalType: string = ArticleType.article;
  additionalOrder: ArticleComponentsOrderModel[] = [];
  additionalTextCounter = 0;
  tinyOptions = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic underline strikebackcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help' /*image*/,
    /*file_picker_types: 'image',
    file_picker_callback(cb, value, meta) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

        input.onchange = (ev: any) => {
          const file = ev.target.files[0];

          const reader = new FileReader();
          reader.onload = () => {

            const id = 'blobid' + (new Date()).getTime();
            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
            let base64 = '';
            if (typeof reader.result === 'string') {
               base64 = ImageUtils.imgResizeBase64(file);
            }
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            cb(blobInfo.blobUri());
          };
          reader.readAsDataURL(file);
        };

        input.click();
  },
    image_description: false,
    image_dimensions: false*/
  };
  textQuillData = {
    intro: '',
    article: '',
    inTextBoxArticle: [],
    quotes: []
  };

  constructor(private formBuilder: FormBuilder, private service: ArticleService, private commonService: CommonService, public modalCtrl: ModalController,
              public loading: LoaderDispatcherService, public platform: Platform, private datePipe: DatePipe) {
    super(platform);
  this.setDataInBox();
  }

  ngAfterViewInit(): void {
    // you can setTimeOut if display some message on your console
    this.canShowEditor = true;
  }

  ngOnInit() {
    let builderGroup = {
      title: ['', [Validators.required]],
      article: ['', [Validators.required]],
      //articleAfterImg: ['', []],
      pubDate: ['', []],
      author: ['', [Validators.required]],
      subTitle: ['', []],
      descrImg: ['', []],
      intro: ['', []],
      photographer: ['', []],
      photographerLink: ['', []],
      city: ['', [Validators.required]]
    };

    if(!this.editMode){
      builderGroup = Object.assign(builderGroup, {slug: ['', [Validators.required]]});
    }
    this.addNewsForm = this.formBuilder.group(builderGroup);
    this.service.loadAuthors(this.env).subscribe(data => {
      this.authors = data.data as AuthorsModel[];
    });

  }

  async addImg() {
    const modalImg = await this.modalCtrl.create({
      animated: true,
      keyboardClose: true,
      component: GalleryModalPage,
      componentProps: {env: this.env, existingImg: this.fileData, source: 'header'}
    });
    modalImg.onDidDismiss().then(data => {
      if (data.role === 'confirm') {
        this.fileData = data.data;
      }
    });
    return modalImg.present();
  }

  async addBodyImg(imgInBody: number) {
    const modalImg = await this.modalCtrl.create({
      animated: true,
      keyboardClose: true,
      component: GalleryModalPage,
      componentProps: {env: this.env, existingImg: this.additionalImgBodyUrls[imgInBody], source: 'body'}
    });
    modalImg.onDidDismiss().then(data => {
      if (data.role === 'confirm') {
        this.additionalImgBodyUrls[imgInBody] = data.data;
      }
    });
    return modalImg.present();
  }

  /*async confirm() {
    await this.alert.chooseBox(
      'Creazione Articolo',
      'Vuoi creare l\'articolo?',
      'Si',
      () => {
        this.loading.show();
        if (!this.addNewsForm.invalid && !!this.fileData) {
          this.service.sendArticleDatas(this.fileData, this.addNewsForm.value.title, new Date(this.addNewsForm.value.pubDate).toUTCString(),
            this.addNewsForm.value.article, this.addNewsForm.value.slug, this.addNewsForm.value.author, this.env)
            .subscribe(() => {
                this.loading.dismiss();
                this.alert.messageBox('Creazione Articolo', 'L\'articolo è stato creato');
                this.addNewsForm.reset();
                this.fileData = null;
                this.fileUrl = null;
              },
              error => {
                this.loading.dismiss();
                const e = error.error as ResponseMessageModel;
                if (e.errorMessages.includes('DUPLICATE_SLUG')) {
                  this.alert.messageBox('Creazione Articolo', 'Identificativo già presente, sceglierne un altro');
                } else {
                  this.alert.messageBox('Creazione Articolo', 'Errore durante la creazione');
                }
              });
        }
      },
      'No');
  }*/

  getSlug() {
    if(!this.editMode) {
      this.loading.show();
      this.commonService.getSlugProposal(this.addNewsForm.value.title, this.slugProposalType, this.env).subscribe(
        value => {
          this.addNewsForm.controls.slug.setValue(value.data);
          this.loading.dismiss();
        }
      );
    }
  }

  isFormValid(): boolean {
    return this.addNewsForm.valid || !!this.fileData;
  }
  isFormInvalid(): boolean {
    return this.addNewsForm.invalid || !this.fileData;

  }

  createArticleField(){
    const text = [new TextBlockModel(this.textQuillData.article, TextBlockEnum.text.toString())];
    /*const textSecond = Object.getOwnPropertyNames(this.addNewsForm.getRawValue()).filter(value => value.includes('articleAfter')).map(value => {
      if(!!this.addNewsForm.value[value]) {
        return this.addNewsForm.value[value];
      }
    });*/
    const textSecond = this.textQuillData.inTextBoxArticle;
    const quotes = this.textQuillData.quotes;
    let imgIndex = 0;
    let quoteIndex = 0;
    this.additionalOrder.forEach((value, index) => {
      if(value.type === TextBlockEnum.imgLink || value.type === TextBlockEnum.imgFile){
        if(typeof this.additionalImgBodyUrls[imgIndex] === 'string') {
          text.push(new TextBlockModel(this.additionalImgBodyUrls[imgIndex], TextBlockEnum.imgLink.toString(), (!!this.addNewsForm.value['imgDescription' + imgIndex]) ? this.addNewsForm.value['imgDescription' + imgIndex] : null));
        } else {
          text.push(new TextBlockModel(this.additionalImgBodyUrls[imgIndex], TextBlockEnum.imgFile.toString(), (!!this.addNewsForm.value['imgDescription' + imgIndex]) ? this.addNewsForm.value['imgDescription' + imgIndex] : null));

        }
        //let img = '<div class="imgbox"><img src="' + this.additionalImgBodyUrls[imgIndex] + '"/>';
        /*if(!!this.addNewsForm.value['imgDescription' + imgIndex]){
          img = img.concat('<p class="photo-description">' + this.addNewsForm.value['imgDescription' + imgIndex] + '</p></div>');
        } else{
          img = img.concat('</div>');
        }*/
        //text.push(img);
        imgIndex ++;
      } else {
        text.push(new TextBlockModel(quotes[quoteIndex], TextBlockEnum.quote.toString()));
        //text.push('<blockquote><p>' + quotes[quoteIndex] + '</p></blockquote>');
        quoteIndex ++;
      }
      text.push(new TextBlockModel(textSecond[index], TextBlockEnum.text));
    });
    return text;
  }

  getDatas() {
    /*const text = [this.addNewsForm.value.article];
    if(!!this.additionalDataBody){
      text.push(this.additionalDataBody);
    }
    const subTexts = [];
    Object.getOwnPropertyNames(this.addNewsForm.getRawValue()).filter(value => value.includes('articleAfter')).forEach(value => {
      if(!!this.addNewsForm.value[value]) {
        subTexts.push(this.addNewsForm.value[value]);
      }
    });
    if (subTexts.length > 0) {
      text.push(subTexts);
    }*/
    const text = this.createArticleField();

    return new ArticleSend(this.fileData, this.addNewsForm.value.title,this.selectedDate,
      text, this.addNewsForm.value.slug, this.addNewsForm.value.author,
      this.addNewsForm.value.subTitle, this.addNewsForm.value.descrImg, this.textQuillData.intro, this.addNewsForm.value.photographer, this.addNewsForm.value.photographerLink, this.addNewsForm.value.city.id);
  }

  /*extractBodyImg(value: string){
    const result: Array<any> = ['', [], []];
    let imgIndex = 0;
    let imgCloseIndex = 0;
    let isFirstIteration = true;
    while (value.indexOf('<img', imgCloseIndex) !== -1){
      imgIndex = value.indexOf('<img', imgCloseIndex);
      if (isFirstIteration){
        result[0] = value.substr(0, imgIndex);
        isFirstIteration = false;
      } else {
        result[2].push(value.substr(imgCloseIndex + 2, imgIndex - imgCloseIndex - 2));
      }
      imgCloseIndex = value.indexOf('/>', imgIndex);
      result[1].push(value.substr(value.indexOf('src="', imgIndex) + 5, value.indexOf('"', value.indexOf('src="', imgIndex) + 5) - 5 - value.indexOf('src="', imgIndex)));
    }
    if(value.indexOf('<img') !== -1) {
      result[2].push(value.substr(imgCloseIndex + 2));
    } else{
      result[0] = value;
    }
    return result;
  }*/
  extractBodyImg(value: string){
    return TextUtils.extractBlocksText(value);

  }

  /*  setDatas(fileData: string, title: string, pubDate: string, article: string, author: number,  subTitle: string, imgDescr: string, intro: string, photographer: string, photographerLink: string) {
    this.fileData = fileData;
    this.addNewsForm.controls.title.setValue(title);
    this.addNewsForm.controls.pubDate.setValue(pubDate);
    const textSplitted = this.extractBodyImg(article);
    this.addNewsForm.controls.article.setValue(textSplitted[0]);
    if(textSplitted.length > 1){
      this.additionalImgBodyUrls = textSplitted[1];
      //this.addNewsForm.controls.articleAfterImg.setValue(textSplitted[2]);
      for (let i = 0; i < Math.max(textSplitted[1].length, textSplitted[2].length); i++){
        this.addNewImg();
        this.addNewsForm.controls['articleAfter' + i].setValue(textSplitted[2][i]);
      }
    }
    this.addNewsForm.controls.subTitle.setValue(subTitle);
    this.addNewsForm.controls.imgDescr.setValue(imgDescr);
    this.addNewsForm.controls.intro.setValue(intro);
    this.addNewsForm.controls.photographer.setValue(photographer);
    this.addNewsForm.controls.photographerLink.setValue(photographerLink);
    this.selectedAuthor = author;
  }
*/
  setDatas(fileData: string, title: string, pubDate: string, article: string, author: number,  subTitle: string, imgDescr: string, intro: string, photographer: string, photographerLink: string, city: string) {
    this.fileData = fileData;
    this.addNewsForm.controls.title.setValue(title);
    this.addNewsForm.controls.pubDate.setValue(this.datePipe.transform(pubDate, 'yyyy-MM-dd HH:mm:ss', 'it'));
    this.addNewsForm.controls.intro.setValue(intro);
    //this.introEditor.writeValue(intro);
    let orderData = null;
    this.extractBodyImg(article).forEach((value, index) => {
      if (index === 0 && value.type === TextBlockEnum.text) {
        //this.addNewsForm.controls.article.setValue(value.data);
        this.textQuillData.article = value.data;
        //this.articleEditor.writeValue(value.data);
        this.addNewsForm.controls.article.setValue(value.data);
      } else {
        switch (value.type){
          case TextBlockEnum.imgLink:
          case TextBlockEnum.imgFile:
            orderData = this.addNewImg();
            //this.textAfterImgEditor.last.writeValue(value.data);
            this.additionalImgBodyUrls.push(value.data);
            //this.textQuillData.inTextBoxArticle.push(value.data);
            this.addNewsForm.controls[orderData.elementId].setValue(value.additional);
            break;
          case TextBlockEnum.quote:
            orderData = this.addNewQuote();
            //this.textAfterQuoteEditor.last.writeValue(value.data);
            this.addNewsForm.controls[orderData.elementId].setValue(value.data);
            this.textQuillData.quotes.push(value.data);
            //this.textQuillData.inTextBoxArticle.push(value.additional);
            break;
          case TextBlockEnum.text:
              this.addNewsForm.controls[orderData.companyElementId].setValue(value.data);
             // this.addNewsForm.controls['articleAfter' + this.additionalTextCounter].setValue(value.data);
              this.textQuillData.inTextBoxArticle.push(value.data);
            break;
        }
        //setTimeout(()=>{console.log(timeout);}, 10000);
      }
    });
    /*if(textSplitted.length > 1){
      this.additionalImgBodyUrls = textSplitted[1];
      //this.addNewsForm.controls.articleAfterImg.setValue(textSplitted[2]);
      for (let i = 0; i < Math.max(textSplitted[1].length, textSplitted[2].length); i++){
        this.addNewImg();
        this.addNewsForm.controls['articleAfter' + i].setValue(textSplitted[2][i]);
      }
    }*/
    this.addNewsForm.controls.subTitle.setValue(subTitle);
    this.addNewsForm.controls.descrImg.setValue(imgDescr);
    //this.addNewsForm.controls.intro.setValue(intro);
    this.addNewsForm.controls.photographer.setValue(photographer);
    this.addNewsForm.controls.photographerLink.setValue(photographerLink);
    this.selectedAuthor = author;

    this.commonService.searchCities(this.env, city.split(' (')[0]).subscribe(data => {
      this.cities = data.data as PlaceModel[];
      this.addNewsForm.controls.city.setValue(this.cities.find(v=>v.city === city.split(' (')[0] &&
        v.districtAbbreviation === city.substr(city.indexOf('(')+1, city.indexOf(')') - city.indexOf('(')-1) &&
        v.region === city.split(', ')[1]
      ));
    });
  }

  private moveImgOutsideP(text: string) {
    let endString = false;
    let imgStart = 0;
    let imgEnd = 0;
    while(!endString) {
      imgStart = text.indexOf('<img', imgEnd);
      imgEnd = text.indexOf('</img>', imgStart) + 5;
      if(imgStart === -1){
        endString = true;
      }

      // verifica se è stato chiuso il p precedente
      if(text.indexOf('</p>', imgStart - 6) >= imgStart){
        const cropText = '<p>' + text.substr(imgStart, imgEnd - imgStart) + '</p>';
        text = text.slice(0, imgStart) + text.slice(imgEnd);
        const newFinishP = text.indexOf('</p>', imgStart) + 3;
        text = text.slice(0, newFinishP) + cropText + text.slice(newFinishP + 1);
      }
    }
    return text;
  }


  resetForm() {
    this.addNewsForm.reset();
    this.fileData = null;
    this.fileUrl = null;
    this.imgsCounter = 0;
    this.quotesCounter = 0;
    this.additionalOrder.length = 0;
    this.additionalImgBodyUrls.length = 0;
    this.setDataInBox();
  }

  resetFormAndSetDate(){
    this.resetForm();
    this.setDataInBox();
  }

  setSlugProposalType(data: string) {
    this.slugProposalType = data;
  }

  setDataInBox(): void {
    this.maxDate = new Date().toISOString();
    this.selectedDate = this.maxDate;
  }

  addNewImg() {
    this.imgsCounter++;
    this.additionalTextCounter++;
    this.additionalOrder.push({type: TextBlockEnum.imgFile.toString(), elementId:'imgDescription' + this.imgsCounter, companyElementId: 'articleAfter' + this.additionalTextCounter});
    //const bckString = this.backupAdditional();
    this.addNewsForm.addControl('imgDescription' + this.imgsCounter, new FormControl(''));
    this.addNewsForm.addControl('articleAfter' + this.additionalTextCounter, new FormControl(''));
    //this.restoreAdditional(bckString);
    return this.additionalOrder[this.additionalOrder.length - 1];
  }

  private removeImg() {
    this.additionalImgBodyUrls.pop();
    this.addNewsForm.removeControl('articleAfter' + this.imgsCounter);
    this.additionalOrder.pop();
    this.imgsCounter --;
  }

  addNewQuote() {

    this.additionalTextCounter++;
    this.quotesCounter++;
    this.additionalOrder.push({type: TextBlockEnum.quote.toString(), elementId:'quote' + this.quotesCounter, companyElementId: 'articleAfter' + this.additionalTextCounter});
    //const bckString = this.backupAdditional();
    //this.additionalsInBody.push(this.quotesCounter);
    this.addNewsForm.addControl('quote' + this.quotesCounter, new FormControl(''));
    this.addNewsForm.addControl('articleAfter' + this.additionalTextCounter, new FormControl(''));
    //this.restoreAdditional(bckString);
    return this.additionalOrder[this.additionalOrder.length - 1];
  }

  private backupAdditional(){
    return Object.getOwnPropertyNames(this.addNewsForm.getRawValue()).filter(value => value.includes('articleAfter')).map(value => {
      if(!!this.addNewsForm.controls[value].value) {
        return this.addNewsForm.value[value];
      }
    });
  }

  private restoreAdditional(data: Array<string>){
    Object.getOwnPropertyNames(this.addNewsForm.getRawValue()).filter(value => value.includes('articleAfter')).forEach((v, i) => {
      if(i < data.length) {
        this.addNewsForm.controls[v].setValue(data[i]);
      } else {
        this.addNewsForm.controls[v].setValue('');
      }
    });
  }

  private removeQuote() {
    this.additionalImgBodyUrls.pop();
    this.addNewsForm.removeControl('articleAfter' + this.quotesCounter);
    this.additionalOrder.pop();
    this.quotesCounter --;
  }

  removeElement(){
    if(this.additionalOrder[this.additionalOrder.length - 1].type === 'img'){
      this.removeImg();
    } else {
      this.removeQuote();
    }
    this.additionalTextCounter--;
  }
  setQuillDatas(data, value){
    this.textQuillData[data] = value.html;
  }

  searchCity($event: { component: IonicSelectableComponent; text: string }) {
    if ($event.text.length > 3) {
      this.commonService.searchCities(this.env, $event.text).subscribe(data => {
        this.cities = data.data as PlaceModel[];
      });
    }
  }
}
