import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GalleryService} from '../../../../core/services/gallery.service';
import {JwtService} from '../../../../core/services/jwt/jwt.service';
import {LoaderDispatcherService} from '../../../../core/services/loader-dispatcher.service';
import {FormGroup} from '@angular/forms';

import {AlertBoxDispatcherService} from '../../../../core/services/alert-box-dispatcher.service';
import {ArticleService} from '../../../../core/services/article.service';
import {ArticleFormComponent} from '../../article-form/article-form.component';
import {EnvType} from '../../../../core/enums/env-type.enum';
import {ScopriloConNoiModel} from '../../../../core/models/scoprilo-con-noi.model';
import {ScopriloConNoiExtraFormComponent} from '../../scoprilo-con-noi-extra/scoprilo-con-noi-extra-form.component';
import {ScopriloConNoiService} from '../../../../core/services/scoprilo-con-noi.service';
import {CommonService} from '../../../../core/services/common.service';
import {TextUtils} from '../../../../utils/text-utils';
import {ScopriloConNoiSend} from '../../../../core/models/internal/scoprilo-con-noi-send';
import {TextBlockEnum} from "../../../../core/enums/text-block.enum";
import {TextBlockModel} from "../../../../core/models/internal/text-block.model";
import {combineLatest} from "rxjs";
import {ResponseMessageModel} from "../../../../core/models/ResponseMessageModel";


@Component({
  selector: 'app-scoprilo-edit-modal',
  templateUrl: './scoprilo-edit-modal.page.html',
  styleUrls: ['./scoprilo-edit-modal.page.scss'],
})
export class ScopriloEditModalPage implements OnInit, AfterViewInit {
  @ViewChild('form') formData: ArticleFormComponent;
  @ViewChild('form2') formData2: ScopriloConNoiExtraFormComponent;
  existingImg: File | string;
  file: File | string;
  imgs: Array<string>;
  env = EnvType.prod;
  data: ScopriloConNoiModel;
  addNewsForm: FormGroup;

  constructor(private modalController: ModalController, private gallery: GalleryService, private jwt: JwtService, public loading: LoaderDispatcherService, private alert: AlertBoxDispatcherService, private service: ArticleService, private scopriloService: ScopriloConNoiService, private common: CommonService) {
  }

  ngOnInit() {
  }

  async confirmScopriloConNoi(form: ArticleFormComponent, form2: ScopriloConNoiExtraFormComponent) {
    const datas = form2.completeDatas(form.getDatas());
    if (!datas.slug) {
      datas.slug = this.data.slug;
    }
    await this.alert.chooseBox(
      'Modifica Articolo',
      'Vuoi modificare questo Scoprilo Con Noi?',
      'Si',
      () => {
        this.loading.show();
        if (form.isFormValid()) {
           if(!Array.isArray(datas.articleText)){
            if(typeof datas.articleText === 'string') {
              this.sendScopriloDatas(datas, datas.articleText, form, form2);
            } else {
              const dataText = datas.articleText as Array<TextBlockModel>
              this.sendScopriloDatas(datas, dataText[0].data, form, form2);
            }
          } else {
            /*if (typeof datas.articleText[1] === 'string'){
              this.sendScopriloDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], datas.articleText[1], datas.articleText[2]), form);
            } else{*/

             if(!!datas.articleText) {
               const imgLoad = datas.articleText.filter(value => value.type === TextBlockEnum.imgFile);
               if(imgLoad.length !== 0) {
                 combineLatest(imgLoad.map(value => this.service.sendImgData([value.data], this.env, '/body/'))).subscribe(value => {
                   (datas.articleText as TextBlockModel[]).filter(v => v.type === TextBlockEnum.imgFile).forEach((v, i) => {
                     v.data = value[0].data[0];
                     v.type = TextBlockEnum.imgLink;
                   });
                   this.sendScopriloDatas(datas, TextUtils.mergeBlocksText(datas.articleText as TextBlockModel[]), form, form2);
                 });
               } else {
                 this.sendScopriloDatas(datas, TextUtils.mergeBlocksText(datas.articleText as TextBlockModel[]), form, form2);
               }
             }

            /*if(datas.articleText[1].length > 0) {
              this.service.sendImgData(datas.articleText[1], this.env, '/body/').subscribe(value => {
                this.sendScopriloDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], value.data, datas.articleText[2]), form);
              });
            } else {
              this.sendScopriloDatas(datas, TextUtils.mergeImgInText(datas.articleText[0], [], datas.articleText[2]), form);
            }*/
           // }
          }
        }
      },
      'No');
  }

  private sendScopriloDatas(datas: ScopriloConNoiSend, textArticle: string, form: ArticleFormComponent, form2: ScopriloConNoiExtraFormComponent){
    this.scopriloService.editScopriloDatas(datas.fileData, datas.title, datas.date,
      textArticle, datas.slug, datas.author, datas.city, datas.placeType, this.env, datas.intro, datas.imgDescr, datas.subTitle,
      datas.photographer, datas.photographerLink, datas.price, datas.valuta, datas.moreInfoUrl, datas.street, datas.ticketInfo)
      .subscribe(() => {
          this.loading.dismiss();
          this.alert.messageBox('Modifica Scoprilo Con Noi', 'Scoprilo con Noi è stato modificato');
          form.resetForm();
          form2.resetForm();
          this.modalController.dismiss({}, 'confirm');
        },
        error => {
          this.loading.dismiss();
          const e = error.error as ResponseMessageModel;
          if (e.errorMessages.includes('DUPLICATE_SLUG')) {
            this.alert.messageBox('Modifica Scoprilo Con Noi', 'Identificativo già presente, sceglierne un altro');
          } else {
            this.alert.messageBox('Modifica Scoprilo Con Noi', 'Errore durante la modifica');
          }
        });
  }

  undo() {
    this.modalController.dismiss({}, 'undo');
  }

  selectedImg(url: string) {
    this.file = url;
  }

  checkFileMessage() {
    return !!this.file && this.file instanceof File;
  }

  ngAfterViewInit(): void {
    this.common.getAuthorId(this.env, this.data.authorSlug).subscribe(r => {
      const id = r.data.id as number;
      this.formData.setDatas(this.data.img, this.data.title, this.data.pubDate, this.data.description, id, this.data.subTitle, this.data.imgDescription, this.data.intro, this.data.photoReferenceName, this.data.photoReferenceLink, this.data.place);
    });
    this.formData2.setDatas(this.data.moreInfoUrl, this.data.valuta, this.data.price, this.data.street, this.data.ticketsInfo, this.data.placeType);

  }
}


