import {ArticleSend} from './article-send';

export class ScopriloConNoiSend extends ArticleSend{
  price?: number;
  valuta?: string;
  moreInfoUrl?: string;
  street?: string;
  ticketInfo?: string;
  placeType: string;

  constructor(fileData, title, date, articleText, slug, author, city, placeType, price?, valuta?, moreInfoUrl?, street?, ticketInfo?, subTitle?, imgDescr?, intro?, photographer?, photographerLink?) {
    super(fileData, title, date, articleText, slug, author, subTitle, imgDescr, intro, photographer, photographerLink, city);
    if(!!price) {
      this.price = price;
    }
    if(!!valuta) {
      this.valuta = valuta;
    }
    if(!!moreInfoUrl) {
      this.moreInfoUrl = moreInfoUrl;
    }
    if(!!street) {
      this.street = street;
    }

    if(!! ticketInfo) {
      this.ticketInfo = ticketInfo;
    }

    if(!!placeType){
      this.placeType = placeType;
    }
  }
}
