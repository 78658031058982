import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MenuEntries} from '../../core/models/menu-entries';
import {MenuStructure} from '../../core/menu-structure';
import {IonSelect, ModalController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {setMenuList} from "../../core/store/menu/actions/menu.actions";
import {Router} from "@angular/router";
import {selectCurrentMenuList} from "../../core/store/menu/selectors/menu.selectors";

@Component({
  selector: 'app-env-modal',
  templateUrl: './env-modal.component.html',
  styleUrls: ['./env-modal.component.scss'],
})
export class EnvModalComponent implements OnInit {
  @Output() menu: EventEmitter<MenuEntries[]> = new EventEmitter<MenuEntries[]>();
  @ViewChild('project') projectControl: IonSelect;
  projects: string[];
  envs: string[];
  currentEnv: string;
  currentProj: string;
  currentEnvModel: string;
  constructor(private store: Store, private router: Router, private modalController: ModalController) {
  }

  ngOnInit() {
    this.projects = MenuStructure.structure
      .filter((value) => !!value.type && value.type === 'project')
      .map((value) => value.title);
    this.projChange(this.projects[0]);
  }

  projChange(proj: any) {
    this.store.select(selectCurrentMenuList).subscribe(state => {
      this.envs = MenuStructure.structure
        .find((value) => value.title === proj && value.type === 'project').subMenu
        .filter((value) => !!value.type && value.type === 'env')
        .map((value) => value.title);
      this.currentProj = state[1] as string;
      this.currentEnv = state[2] as string;
    }).unsubscribe();

    /*this.envs = MenuStructure.structure
      .find((value) => value.title === proj && value.type === 'project').subMenu
      .filter((value) => !!value.type && value.type === 'env')
      .map((value) => value.title);
    this.currentProj = proj;*/
    //this.envChange(this.projects[0], this.envs[0]);
  }

  /*envChange(proj: string, env: any) {
    this.store.dispatch(setMenuList({
      menuList:
        {
          ...MenuStructure.structure.find(value => value.title === 'Dashboard'),
          ...MenuStructure.structure
            .find((value) => value.title === proj).subMenu
            .find((value) => value.title === env).subMenu
        }
    }));
    this.router.navigateByUrl('/dashboard');
  }*/

  undo() {
    this.modalController.dismiss({}, 'undo');
  }

  confirm() {

  const data = MenuStructure.structure
      .find((value) => value.title === this.currentProj).subMenu
      .find((value) => value.title === this.currentEnv).subMenu;
    this.store.dispatch(setMenuList({menuList: data, project: this.currentProj, env: this.currentEnv}));
    this.modalController.dismiss({}, 'confirm');
  }

  changeEnv($event) {
    this.currentEnv = $event.detail.value
  }
}
