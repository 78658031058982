import { Injectable } from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ProjectName} from '../enums/project-name.enum';

import {HttpClient} from '@angular/common/http';
import {EnvironmentsService} from './environments.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {EnvType} from '../enums/env-type.enum';
import {ArticleService} from "./article.service";
import {UrlUtils} from "../../utils/url-utils";
import {ResponseMessageModel} from "../models/ResponseMessageModel";

@Injectable({
  providedIn: 'root'
})
export class ScopriloConNoiService {

  constructor(private http: HttpClient, private env: EnvironmentsService, private jwtHelper: JwtHelperService) { }

  sendScopriloDatas(imgFile: File | string, title: string, date: string, article: string, slug: string, idAuthor: number, city: string, placeType: string, env: string, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, price?: number, valuta?: string, moreInfoUrl?: string, street?: string, ticketInfo?: string){
    if (imgFile instanceof File){
      return this.sendImgData(imgFile, env).pipe(
        switchMap(value => this.createArticle(title, date, value.data[0], article, slug, idAuthor,city, placeType, env, intro ,imgDescr, subtitle, photographer, photographerLink, price, valuta, moreInfoUrl, street, ticketInfo))
      );
    } else {
      return this.createArticle(title, date, imgFile, article, slug, idAuthor,city, placeType, env, intro ,imgDescr, subtitle, photographer, photographerLink, price, valuta, moreInfoUrl, street, ticketInfo);
    }
  }

  editScopriloDatas(imgFile: File | string, title: string, date: string, article: string, slug: string, idAuthor: number, city: string, placeType: string, env: string, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, price?: number, valuta?: string, moreInfoUrl?: string, street?: string, ticketInfo?: string){
    if (imgFile instanceof File){
      return this.sendImgData(imgFile, env).pipe(
        switchMap(value => this.editArticle(title, date, value.data, article, slug, idAuthor, city, placeType, env, intro ,imgDescr, subtitle, photographer, photographerLink, price, valuta, moreInfoUrl, street, ticketInfo))
      );
    } else {
      return this.editArticle(title, date, imgFile, article, slug, idAuthor,city, placeType, env, intro ,imgDescr, subtitle, photographer, photographerLink, price, valuta, moreInfoUrl, street, ticketInfo);
    }
  }
  public editArticle(title: string, date: string, imgUrl: string, article: string, slug: string, idAuthor: number, city: string, placeType: string, env: string, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, price?: number, valuta?: string, moreInfoUrl?: string, street?: string, ticketInfo?: string){
    imgUrl = UrlUtils.changeCoreUrlWithWeb(imgUrl, this.env.getCoreBeEnvUrl(env, ProjectName.scoprilo), this.env.getWebBeEnvUrl(env, ProjectName.scoprilo));
    return this.http.put(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/scopriloconnoi/edit`,
      {title, date, imgUrl, article, slug, slugAuthor: idAuthor, city, placeType, price, valuta, moreInfoUrl, street, ticketInfo, intro ,imgDescr, subtitle, photographer, photographerLink}, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  public createArticle(title: string, date: string, imgUrl: string, article: string, slug: string, idAuthor: number, city: string, placeType: string, env: string, intro: string ,imgDescr: string, subtitle: string, photographer: string, photographerLink: string, price?: number, valuta?: string, moreInfoUrl?: string, street?: string, ticketInfo?: string){
    imgUrl = UrlUtils.changeCoreUrlWithWeb(imgUrl, this.env.getCoreBeEnvUrl(env, ProjectName.scoprilo), this.env.getWebBeEnvUrl(env, ProjectName.scoprilo));
    return this.http.post(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/scopriloconnoi/createscopriloconnoi`,
      {title, date, imgUrl, article, slug, slugAuthor: idAuthor, city, placeType, price, valuta, moreInfoUrl, street, ticketInfo, intro ,imgDescr, subtitle, photographer, photographerLink}, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }

  public sendImgData(file: File, env: string){
    const formData = new FormData();
    formData.append('image0', file, file.name);
    formData.append('path', '/');
    formData.append('length', '1');
    return this.http.post(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/common/loadimg`, formData, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  filter(env: EnvType, referral: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/scopriloconnoi/filterscopriloconnoi?referral=${referral}`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  getPlaceType(env: EnvType) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/scopriloconnoi/getplacetypes`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  delete(slug: string, env: string) {
    return this.http.delete(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/scopriloconnoi/delete/${slug}`,
      {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}
    ).pipe(map((data) => data as ResponseMessageModel));
  }
}
