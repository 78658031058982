import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {Storage} from '@ionic/storage';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JwtService} from '../services/jwt/jwt.service';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private storage: Storage, private jwtHelper: JwtHelperService, private jwt: JwtService,
              private auth: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //const s = new Storage();
    //return !!s.get('currentUser');
    if(this.jwt.getToken() !== ''){
      return this.remoteCheck().then(value => true, reason => {
        this.router.navigateByUrl('login');
        return false;
      });
    } else {
      this.router.navigateByUrl('login');
      return false;
    }
  }
  async getToken() {
    let token = false;
    await this.storage.ready().then(async () => await this.storage.get('access_token').then(value => {
      if(value !== '') {
        token = !this.jwtHelper.isTokenExpired(value);
      } else {
        token = false;
      }
    }));
    return token;
  }

  private async remoteCheck() {
      this.jwt.getTokenCallback(async token => await this.auth.check(token).pipe().toPromise().then(value => true, reason => false));
  }
}
