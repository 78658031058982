import {MenuEntries} from './models/menu-entries';

export class MenuStructure {
  static structure: MenuEntries[] = [
    {
      title: 'Dashboard',
      iosIcon: 'speedometer-outline',
      mdIcon: 'speedometer-sharp',
      url: '/dashboard'
    },
    {
        title: 'Scoprilo',
        icon: 'assets/scoprilo-icon.svg',
        type: 'project',
        toggle: false,
        subMenu: [
          {
            title: 'Production',
            iosIcon: 'sunny-outline',
            mdIcon: 'sunny-sharp',
            type: 'env',
            toggle: false,
            subMenu: [
              {
                title: 'Articoli',
                iosIcon: 'newspaper-outline',
                mdIcon: 'newspaper-sharp',
                toggle: false,
                subMenu: [
                  {
                    title: 'Aggiungi Articolo',
                    iosIcon: 'add-circle-outline',
                    mdIcon: 'add-circle-sharp',
                    url: '/scoprilo/prod/add-articles'
                  },
                  {
                    title: 'Gestisci Articoli',
                    iosIcon: 'settings-outline',
                    mdIcon: 'settings-sharp',
                    url: '/scoprilo/prod/manage-articles'
                  }
                ]
              }
            ]
          },
          {
            title: 'Devel',
            iosIcon: 'thunderstorm-outline',
            mdIcon: 'thunderstorm-sharp',
            type: 'env',
            toggle: false,
            subMenu: [
              {
                title: 'Articoli',
                iosIcon: 'newspaper-outline',
                mdIcon: 'newspaper-sharp',
                toggle: false,
                subMenu: [
                  {
                    title: 'Aggiungi Articolo',
                    iosIcon: 'add-circle-outline',
                    mdIcon: 'add-circle-sharp',
                    url: '/scoprilo/devel/add-articles'
                  },
                  {
                    title: 'Gestisci Articoli',
                    iosIcon: 'settings-outline',
                    mdIcon: 'settings-sharp',
                    url: '/scoprilo/devel/manage-articles'
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
}


