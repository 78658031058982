import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvironmentsService} from "./environments.service";

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  constructor(private http: HttpClient, private env: EnvironmentsService) { }

  checkVersion(version: string, type: string) {
    return this.http.post(`${this.env.loginEndpoint}v1/konpad/checkversion`, {version, type});
  }
}
