import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { ScopriloConNoiExtraFormComponent } from './scoprilo-con-noi-extra-form.component';
import {QuillModule} from 'ngx-quill';
import {EditorModule} from '@tinymce/tinymce-angular';
import {IonicSelectableModule} from "ionic-selectable";
import {HeaderModule} from "../../header/header.module";
import {ImageRemotePickerModule} from "../../image-remote-picker/image-remote-picker.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderModule,
        ReactiveFormsModule,
        ImageRemotePickerModule,
        QuillModule,
        EditorModule,
        IonicSelectableModule,
    ],
  exports: [
    ScopriloConNoiExtraFormComponent
  ],
  declarations: [ScopriloConNoiExtraFormComponent]
})
export class ScopriloConNoiExtraFormModule {}
