import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicStorageModule, Storage} from '@ionic/storage';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './routes/app-routing.module';
import {MenuEntryModule} from './components/menu-entry/menu-entry.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EnvironmentsService} from './core/services/environments.service';
import {JWT_OPTIONS, JwtInterceptor, JwtModule} from '@auth0/angular-jwt';
import {RouteSecurityInterceptor} from './core/interceptors/route-security.interceptor';
import {StorageService} from './core/services/storage.service';
import {JwtService} from './core/services/jwt/jwt.service';
import {ImageRemotePickerModule} from './components/image-remote-picker/image-remote-picker.module';
import {QuillModule} from 'ngx-quill';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {HTTP} from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {GalleryModalPage} from './components/scoprilo/modals/gallery-modal/gallery-modal.page';
import {ReactiveFormsModule} from '@angular/forms';
import {NewsEditModalPage} from './components/scoprilo/modals/news-edit-modal/news-edit-modal.page';
import {ArticleFormModule} from './components/scoprilo/article-form/article-form.module';
import {ScopriloEditModalPage} from './components/scoprilo/modals/scoprilo-edit-modal/scoprilo-edit-modal.page';
import {ScopriloConNoiExtraFormModule} from './components/scoprilo/scoprilo-con-noi-extra/scoprilo-con-noi-extra-form.module';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {BackgroundMode} from '@ionic-native/background-mode/ngx';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {menuReducer} from './core/store/menu/reducers/menu.reducer';
import {HeaderEnvModule} from './components/env-modal/header-env.module';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () =>
      storage.getToken('access_token')
    ,
    whitelistedDomains: ['feroast.com', 'apidevel.feroast.com', 'api.feroast.com', 'scoprilo.net', 'apidevel.scoprilo.net', 'api.scoprilo.net'],
    blacklistedRoutes: [
      'https://auth.scoprilo.net/v1/oauth/token'
    ],
  };
}

@NgModule({
  declarations: [AppComponent, GalleryModalPage, NewsEditModalPage, ScopriloEditModalPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MenuEntryModule,
    HttpClientModule,
    NgbModule,
    IonicStorageModule.forRoot({driverOrder: ['sqlite', 'websql', 'indexeddb']}),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [JwtService]
      }
    }),
    ImageRemotePickerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{size: ['small', false, 'large', 'huge']}],
          ['bold', 'italic', 'underline', 'strike'],
          [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{indent: '-1'}, {indent: '+1'}],
          ['clean'],
          ['link', 'image', 'video']
        ]
      },
    }),
    EditorModule,
    ReactiveFormsModule,
    ArticleFormModule,
    ScopriloConNoiExtraFormModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('menuStore', menuReducer),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([]),
    HeaderEnvModule,
    QuillModule.forRoot({
      theme: 'snow',
      format: 'html'
    })
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: RouteSecurityInterceptor, multi: true},
    DatePipe,
    StorageService,
    AppVersion,
    HTTP,
    File,
    FileOpener,
    LocalNotifications,
    BackgroundMode,
    {provide: LOCALE_ID, useValue:'it-IT'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static stor: Storage;

  constructor(private env: EnvironmentsService) {
    env.scopriloUrls = {
      devel: {
        konpadBE:'https://devel-apikonpad.scoprilo.net/',
        coreBE: 'https://devel-apicore.scoprilo.net/',
        webBE: 'https://apidevel.scoprilo.net/'
      },
      prod: {
        konpadBE:'https://apikonpad.scoprilo.net/',
        coreBE: 'https://apicore.scoprilo.net',
        webBE: 'https://api.scoprilo.net/'
    }};
    env.loginEndpoint = 'https://auth.scoprilo.net/';
    env.scopriloApiKey = 'uIwrgWrpSgvLi9(GYvgJUIf?k%K$jgnjg{A**DrwgwHUF/(JNF(Ob4qe4ug80hb';
  }
}
