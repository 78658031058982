import {Injectable} from '@angular/core';
//import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  //private _storage: Storage | null = null;

  constructor(/*private storage: Storage*/) {
    //this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    //await this.storage.defineDriver(CordovaSQLiteDriver);
    //await this.storage.create().then(value => {
//      this._storage = value;
     // this._storage.set('access_token', '');
    //});
  }

  // Create and expose methods that users of this service can
  // call, for example:
  async set(key: string, value: any) {
    // return this.storage.set(key, value);
  }

  async get(key: string) {
    //return this.storage.get(key);
    /*let result: string;
    await this.ready().then(value1 => {

    });
    return result;*/
  }

  async ready() {
   // return await this.storage.ready();
  }
}
