import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {UserGuard} from '../core/guards/user.guard';
import {ScopriloRoutingModule} from './scoprilo/scoprilo-routing.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login-page/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [UserGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [UserGuard],
  },
  ...ScopriloRoutingModule.scopriloRoutes
  /*{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login-page/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [UserGuard],
  },
  {
    path: 'scoprilo/devel/add-articles',
    loadChildren: () => import('../pages/scoprilo/devel/add-articles/add-articles-devel.module').then(m => m.AddArticlesPageModule),
    canActivate: [UserGuard],
  },
  {
    path: 'scoprilo/:env/manage-articles',
    loadChildren: () => import('../pages/scoprilo/manage-articles/manage-articles.module').then(m => m.ManageArticlesPageModule),
    canActivate: [UserGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [UserGuard],
  },*/
  /*{
    path: 'gallery-modal',
    loadChildren: () => import('./pages/scoprilo/modals/gallery-modal/gallery-modal.module').then( m => m.GalleryModalPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
