import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AuthRequestModel} from '../models/auth-request.model';
import {EnvironmentsService} from './environments.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private clientId = 2;
  private clientSecret = 'TtO9GBwXoUwXiwOFZOrTx9aFbHkL8JoDRz7gT7cN';
  constructor(private http: HttpClient, private env: EnvironmentsService, private jwtHelper: JwtHelperService) { }

  login(email: string | number, password: string | number){
    return this.http.post(`${this.env.loginEndpoint}v1/oauth/token`,
      {
        username: email,
        password,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        grant_type : 'password',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id : this.clientId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_secret: this.clientSecret,
        scope: ''
      }).pipe(
      map((data) => {
        const d = data as AuthRequestModel;
        return d.access_token;
      })
    );
  }

  logout(token: string){
   return  this.http.post(`${this.env.loginEndpoint}v1/oauth/logout`, {}, { headers: {Authorization: `Bearer ${token}`}, observe: 'response' , responseType: 'text'});
  }


  check(token: string) {
    return this.http.get(`${this.env.loginEndpoint}v1/oauth/check`, {headers: {Authorization: `Bearer ${token}`},observe: 'response'});
  }

  getName(token: string) {
    return this.http.get(`${this.env.loginEndpoint}v1/oauth/getname`, {headers: {Authorization: `Bearer ${token}`}});
  }

  checkFirstPasswordChange(token: string) {
    return this.http.get(`${this.env.loginEndpoint}v1/oauth/ispasswordfirstsetneeded`, {headers: {Authorization: `Bearer ${token}`}}).pipe(map(value => ({firstSet: value['passwFirstSet'], token})));
  }

}
