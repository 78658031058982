import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderDispatcherService {
  loadingSpin;
  constructor(private loading: LoadingController) { }

  async show() {
    this.loadingSpin = await this.loading.create({
      spinner: 'circular',
      duration: 5000,
      translucent: true,
      message: 'Attendi'
    });
    await this.loadingSpin.present();
  }

  dismiss(){
    this.loadingSpin.dismiss();
  }
}
