import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RemotePickerElementComponent} from '../remote-picker-element/remote-picker-element.component';

@Component({
  selector: 'app-image-remote-picker',
  templateUrl: './image-remote-picker.component.html',
  styleUrls: ['./image-remote-picker.component.scss'],
})
export class ImageRemotePickerComponent implements OnInit, OnChanges {
  @Input() imgs: Array<string>= new Array<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  selectedPicker: RemotePickerElementComponent;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  select(picker: RemotePickerElementComponent, url: string) {
    if(!!this.selectedPicker){
      this.selectedPicker.markDeselect();
    }

    this.selectedPicker = picker;
    this.selectedPicker.markSelected();
    this.selected.emit(url);
  }
}
